import List from "@material-ui/core/List";
import {Badge, IconButton, ListItemSecondaryAction, ListSubheader} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));
const defaultProps = {
    color: 'primary'
};

function Items({data}) {

    const classes = useStyles();
    const cardClasses = useStyles();

    // eslint-disable-next-line no-mixed-operators
    const language = navigator.languages && navigator.languages[0] || // Chrome / Firefox
        navigator.language ||   // All browsers
        navigator.userLanguage; // IE <= 10

    console.log(language);

    let whatsappLabel = "Order via Whatsapp";
    let callPhone = "Call phone number";
    let youLike = "You like?";
    let whatWeOffer = "What we offer";
    if (language === "fr-FR") {
        whatsappLabel = "Commander via Whatsapp"
        callPhone = "Appeler le numero"
        youLike = "Vous aimez?"
        whatWeOffer = "Ce que nous offrons"
    }
    if (language === "es-ES") {
        whatsappLabel = "Orden por Whatsapp";
        callPhone = "LLamar el numero";
        youLike = "Te gusta?";
        whatWeOffer = "Lo que ofrecemos"
    }

    const handleClickedItem = (item) => {

        console.log(JSON.stringify(item))
        confirmAlert({
            title: item.name,
            message: item.price,
            buttons: [

                {
                    label: callPhone,
                    onClick: () => contactStoreViaPhoneCall()
                },
                {
                    label: whatsappLabel,
                    onClick: () => contactStoreViaWhatsapp()
                }
            ],
            childrenElement: () => <div>
                <img style={{borderRadius: "10%"}} height={100} width={100} src={item.image} alt="No image"/>
                <p style={{color: "#161E31"}}>{item.description}</p>
                <label style={{color: "#7367F0"}}>{youLike}</label>
                <hr/>
            </div>,
            closeOnEscape: true,
            closeOnClickOutside: true,
            afterClose: () => {
            },
            overlayClassName: "overlay-custom-class-name"
        });
    }

    const contactStoreViaPhoneCall = () => {
        window.open("tel:" + data.storePhone);
    }

    const contactStoreViaWhatsapp = () => {
        window.open(" https://wa.me/" + data.storePhone.replace("+", ""));

    }

    return <List className={classes.root}>
        <ListSubheader style={{color: "#293046"}}>{whatWeOffer}</ListSubheader>
        {data && data.menuItems && data.menuItems.map(item => {
            return <Card key={item.id} className={cardClasses.root} variant="outlined" style={{margin: "3px"}}>
                <ListItem alignItems="flex-start" onClick={() => handleClickedItem(item)}>
                    <ListItemAvatar>
                        <Avatar alt="No image" src={item.image}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}
                        style={{color: "#293046"}}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                </Typography>
                                {item.description ? item.description : "..."}
                            </React.Fragment>
                        }
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="comments">
                            <label
                                style={{
                                    marginTop: "10px", color: "#161E31", fontSize: "12px",
                                    fontWeight: "bold"
                                }}>{item.price}</label>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </Card>
        })}
    </List>
}

export default Items
