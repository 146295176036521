import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';

function StoreInfo({data}) {

    let storeEmail = "";
    let storePhone = "";
    if (data.storeEmail) {
        storeEmail = data.storeEmail;
    }

    if (data.storePhone) {
        storePhone = data.storePhone;
    }

    return <div>
        <CardHeader
            title={data.storeName && data.storeName}
            subheader={storePhone + " " + storeEmail}
        />
        <div>
            <img src={data.storeLogo} className="img img-rounded" width={200} height={100}/>
        </div>
    </div>
}

export default StoreInfo;
