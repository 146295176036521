// eslint-disable-next-line no-unused-vars
import './App.css';
import axios from "axios";
import React, {Fragment, useEffect, useState} from "react";
import StoreInfo from "./StoreInfo";
import Items from "./Items";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ScrollView} from 'react-native';
import * as qs from 'query-string';

function App() {

    const urlParams = qs.parse(window.location.search);
    let id = urlParams.id;
    let [data, setData] = useState([]);
    let [selectedCategory, setSelectedCategory] = useState({id: "", name: ""})
    let [isLoading, setIsLoading] = useState(false);
    let [showCategories, setShowCategories] = useState(false);
    let [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        getProducts();
        window.addEventListener('scroll', handleScroll);
    }, [selectedCategory]);

    function handleScroll() {

        setShowCategories(true);
    }

    function handleSelectedCategory(item) {

        if (item.name === "All") {
            item.id = "";
        }
        setSelectedCategory({
            id: item.id,
            name: item.name
        });
    }

    return (

        <div className="row" style={{

            borderStyle: "groove",
            margin: "5px"
        }}>
            <div className="col-md-12">
                <StoreInfo data={data}/>
                {isLoading && <CircularProgress style={{position: "absolute", right: 5}} color="secondary"/>}
                <Items data={data}/>
                <label style={{color: "red"}}>{errorMessage}</label>
            </div>
            {showCategories &&
            <div className="col-md-12" style={{position: "fixed", bottom: "0", left: "0", right: "0"}}>
                <ScrollView horizontal={true}>
                    <ButtonGroup color="primary" style={{height: "50px"}}>
                        {data.menuCategories && data.menuCategories.map(item => {
                            return <Button onClick={() => handleSelectedCategory(item)}
                                           style={{
                                               textTransform: "lowercase", backgroundColor: "#072448",
                                               color: "white",
                                               height: "60px",
                                               maxWidth: "120px",
                                               width: "120px"
                                           }}>{item.name}</Button>
                        })}
                    </ButtonGroup>
                </ScrollView>
            </div>
            }
        </div>

    );

    function getProducts() {
        setIsLoading(true);
        const options = {
            url: "https://torapos-live.herokuapp.com/online/stores/" + id + "/menu?page=0&size=1000&categoryId="
                + selectedCategory.id,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        };

        axios(options)
            .then(response => {
                setIsLoading(false);
                setData(response.data);
            })
            .catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.response.data.message)
            });
    }
}

export default App;
